import { FC, memo, lazy } from "react";
import { GlobalMessage } from "./internal/GlobalMessage";
import { useUserInformation } from "../../util/useUserInformation";
import { Menu } from "./internal/Menu";
import {
  createBoxVariantComponents,
  createMostlyFluidVariantComponents,
} from "../../components/setup-factories";
import styled from "styled-components/macro";
import { Header } from "./internal/Header";
import { BoxPage } from "../../components/BoxPage";
import { useLocation } from 'react-router-dom'

const AdminHeader = lazy(() =>
  import("./internal/header-notification/NotificationAdmin").then(
    (exports) => ({ default: exports.NotificationAdmin })
  )
);
const AudiologistHeader = lazy(() =>
  import("./internal/header-notification/NotificationAudiologist2").then(
    (exports) => ({
      default: exports.NotificationAudiologist,
    })
  )
);
// const WarehouseWorkerHeader = lazy(() =>
//   import("./internal/header-notification/NotificationWarehouseWorker").then(
//     (exports) => ({
//       default: exports.NotificationWarehouseWorker,
//     })
//   )
// );

const { MostlyFluidMenu } = createMostlyFluidVariantComponents({
  Menu: {
    widths: ["130px", "calc(100% - 130px)"],
    alignItems: "stretch",
    breakpoints: { medium: { widths: ["100%"] }, small: { widths: ["100%"] } },
  },
});

const { BoxContent } = createBoxVariantComponents({
  Content: {
    boxSizing: "border-box",
    width: { max: "calc(1500px + 2 * 16px)" }, // adjust for horizontal padding

    margin: { left: "auto", right: "auto" },
    padding: {
      top: "32px",
      right: "16px",
      // bottom: "400px",
      left: "16px",
    },
    flexGrow: 0,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
});

/**
 * it would be ideal to use a mostly-fluid component here, but this is practially impossible due to the issue described here:
 * @see https://gitlab.com/webnuts/npm/browser/component-factories/-/issues/92
 */
const HeaderContainer = styled.div`
  > * {
    margin-bottom: 20px;
  }
`;

export const LayoutAuthorized: FC = memo(({ children }) => {
  const {
    hasAdminReadAccess,
    hasAudiologReadAccess,
    hasWarehouseWorkerReadAccess,
  } = useUserInformation();

  const location = useLocation();
  const pathsMaximumWidthPage = ["/warehouse-worker/audiologist-stock/overview", "/audiologist/audiologist-stock/overview", "/mixed/customer-lists/overview", "/admin/time/overview", "/audiologist/order/create-order", "/audiologist/order/order-list"];
  const pathsMediumWidthPage = ["/admin/overtime/overview"];
  const pathsWithHeight = ["/audiologist/order/create-order", "/audiologist/order/order-list"];
  const pathsWithoutHeader = ["/audiologist/order/create-order", "/audiologist/order/order-list", "/all/calculator"];
  // const style = pathsMaximumWidthPage.includes(location.pathname)
  //     ? {padding: "5px", maxWidth: "100%"}
  //     : pathsMediumWidthPage.includes(location.pathname)
  //         ? {padding: "5px", maxWidth: "80%"}
  //         : {};
  let style = {};
  if (pathsMaximumWidthPage.includes(location.pathname))
    style = {...style, padding: "5px", maxWidth: "100%"};
  if (pathsMediumWidthPage.includes(location.pathname))
    style = {...style, padding: "5px", maxWidth: "80%"};
  if (pathsWithHeight.includes(location.pathname))
    style = {...style, height: "100vh"};
  if (pathsWithoutHeader.includes(location.pathname))
    style = {...style, padding: "0px"};

  return (
    <>
      <Header />
      <BoxPage>
        <MostlyFluidMenu>
          <Menu />
          <BoxContent style={{...style, minHeight: "100vh"}}>
            {!pathsWithoutHeader.includes(location.pathname) &&
              <HeaderContainer>
                {(hasAdminReadAccess || hasWarehouseWorkerReadAccess) && <AdminHeader />}
                {hasAudiologReadAccess && <AudiologistHeader />}
                {/*{hasWarehouseWorkerReadAccess && <WarehouseWorkerHeader />}*/}
                <GlobalMessage />
              </HeaderContainer>
            }
            {children}
          </BoxContent>
        </MostlyFluidMenu>
      </BoxPage>
    </>
  );
});
