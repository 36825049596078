import { FC, memo, Suspense, useMemo } from "react";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { RootRouter } from "./Router";
import { PageLoading } from "./pages/PageLoading";
import { PageGenericError } from "./pages/PageGenericError";
import {useSingularView, useView2} from "./services";
import { UserInformationContext } from "./util/useUserInformation";
import { getSessionToken } from "./util/getSessionToken";
import { Login } from "./pages/Login";
import { useUnauthorizedAction } from "./util/useUnauthorizedAction";
import { LayoutPlain } from "./layouts/LayoutPlain";
import {useSignOut} from "./layouts/LayoutAuthorized/internal/use-sign-out";

const history = createBrowserHistory({ basename: "" });

export const App: FC = memo(() => {
  const sessionToken = getSessionToken();
  useUnauthorizedAction();

  return (
    <Router history={history}>
      {sessionToken === undefined ? <Login /> : <WithSessionToken />}
    </Router>
  );
});

const WithSessionToken = () => {
  const { WhoAmI } = useSingularView("WhoAmI", {});
  const { WhoAmI2 } = useView2("WhoAmI2", {}, "staff/who-am-i");
  const { signOut } = useSignOut();

  // const { MyCartSummary, loadView: loadMyCartSummary } = useSingularView(
  //   "MyCartSummary",
  //   {},
  //   {
  //     deferredFetch: true, // we should only fetch this view, if the user is audiologist
  //   }
  // );

  // useEffect(() => {
  //   if (
  //     WhoAmI.status === "ok" &&
  //     WhoAmI.result.roles.some(
  //       (role) => role === "audiologist" || role === "warehouse-worker"
  //     )
  //   ) {
  //     loadMyCartSummary();
  //   }
  // }, [WhoAmI.status, WhoAmI.result?.roles, loadMyCartSummary]);

  const loading = useMemo(
    () => (
      <LayoutPlain>
        <PageLoading />
      </LayoutPlain>
    ),
    []
  );

  if (
      WhoAmI.status === "initial" || WhoAmI.status === "loading" ||
      WhoAmI2.status === "initial" || WhoAmI2.status === "loading"
  ) {
    return (
        <Suspense fallback={loading}>
            {loading}
        </Suspense>
    );
  }

    if (WhoAmI2.status === "error") { // TODO: Delete after a few weeks when every user has had a chance to get a new token with data from the new "staff" table
        signOut();
    }

    if (
      WhoAmI.status === "error" || WhoAmI2.status === "error"
  ) {
      return (
          <Suspense fallback={loading}>
              <LayoutPlain>
                  <PageGenericError/>
              </LayoutPlain>
          </Suspense>
      );
  }

  return (
    <Suspense fallback={loading}>
        <UserInformationContext.Provider
          value={{
            isSignedOn: true,
            userKey: WhoAmI.result.userKey,
            staffId: WhoAmI2.result.staffId,
            resourceId: WhoAmI2.result.resourceId,
            country: WhoAmI2.result.country,
            roles: WhoAmI.result.roles,
            region: WhoAmI.result.region,
            // productsInCart: MyCartSummary.status === "ok" ? MyCartSummary.result.quantity : 0,
            // reloadCart: () => {
            //   loadMyCartSummary();
            // },
            apiTokenSiniInvoice: WhoAmI.result.apiTokenSiniInvoice,
          }}
        >
          <RootRouter />
        </UserInformationContext.Provider>
    </Suspense>
  );
};